import React, { useEffect, useState, FC } from "react";
import { ethers } from "ethers";
import Config from "./Config";
import artifact from "../abi/DemoVote.json";

declare const window: any;

const contractAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3"

// ===============================================================
// コントラクトと接続して、値を取得
// ===============================================================
const useContent = (
    contract: ethers.Contract
  ) => {
    // Vote型
    type Vote = {
      message: string,
    }

	  // ABIで定義された関数を取得
    const { votes, allVoteCount, voteCountOfFirstChoice, voteCountOfSecondChoice, isVoted, addVote } = contract.functions;

    // useStateを使ってコンポーネント内で値を取得
	  const [account, setAccount] = useState<string>(""); // metamaskアドレス
	  const [voteChoice, setVoteChoice] = useState<string>(""); // 投票の選択
    const [voteMessage, setVoteMessage] = useState<string>(""); // 投票メッセージ
    const [isVotedValue, setIsVotedValue] = useState<Boolean>(); // 投票済みかどうか
    const [votesValue, setVotesValue] = useState<Vote[]>([]); // 投票の構造体

    // 投票数
    const [voteCountOfAllValue, setAllVoteCountValue] = useState<string>("");
    const [voteCountOfFirstChoiceValue, setVoteCountOfFirstChoiceValue] = useState<string>("");
	  const [voteCountOfSecondChoiceValue, setVoteCountOfSecondChoiceValue] = useState<string>("");
	  
    // 画面の入力値
    const updateVoteChoice = (e: React.ChangeEvent<HTMLInputElement>) => setVoteChoice(e.target.value);
    const updateVoteMessage = (e: React.ChangeEvent<HTMLInputElement>) => setVoteMessage(e.target.value);

    // 投票
    const requestVote = async () => {
	    if (voteChoice == null) {
	    	return;
	    } else {
	    	await addVote(voteChoice, voteMessage); // 実際にコントラクトをコールする部分
	    }
    };

		//コントラクト情報を取得する
  	useEffect(() => {
    	if (typeof window.ethereum !== 'undefined') {
      	(async () => {
        	const account = await connectMetamask();
        	setAccount(account);
      	})();
    	}
  	}, []);

    // コンポーネントマウント時に関数をコールする
    useEffect(() => {
      // 投票数の取得
      const getVoteCount = async () => {
        // 1つ目の選択肢
        const _voteCountOfFirst = await voteCountOfFirstChoice();
        setVoteCountOfFirstChoiceValue(_voteCountOfFirst);
        // 2つ目の選択肢
		    const _voteCountOfSecond = await voteCountOfSecondChoice();
        setVoteCountOfSecondChoiceValue(_voteCountOfSecond);
        // 総票数
		    const _voteCountOfAll = await allVoteCount();
        setAllVoteCountValue(_voteCountOfAll);
      }
      getVoteCount();

      // 投票済みかどうか取得する
      const getIsVoted = async () => {
        const _isVoted = await isVoted();
        setIsVotedValue(_isVoted);
      }
      getIsVoted();

      // メッセージの表示
      const getVoteMessages = async () => {
        const _allVoteCount = await allVoteCount();
        setAllVoteCountValue(_allVoteCount);
        const _votes = []
        for (let i = 1; i <= _allVoteCount; i++) {
          const _vote = await votes(i);
          _votes.push({
            ..._vote,
            id: i
          })
        }
        setVotesValue(_votes);
      }
      getVoteMessages();

      // event取得
      const catchContractEvent = (voteCountOfFirstChoice: Number, voteCountOfSecondChoice: Number, isVoted: Boolean) => {
        console.log("犬の得票数: ", String(voteCountOfFirstChoice));
		    console.log("猫の得票数: ", String(voteCountOfSecondChoice));
		    console.log("投票したかどうか: ", Boolean(isVoted));
      };
      const filter = contract.filters.Added(null,null,null);
      contract.on(filter, catchContractEvent);
    }, [])

    return {
			account: account,
      isVoted: isVotedValue,
      voteMessage: votesValue,
      voteCountOfAll: voteCountOfAllValue,
      voteCountOfFirstChoice: voteCountOfFirstChoiceValue,
			voteCountOfSecondChoice: voteCountOfSecondChoiceValue,
      requestVote,
			updateVoteChoice,
      updateVoteMessage,
    }
  }

/**
 * メタマスクと接続して、アドレスを返す
 */
const connectMetamask = async (): Promise<string> => {
  try {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    if (accounts.length > 0) {
			console.log('Welcome to MetaMask User.');
      return accounts[0] as string;
    }
  } catch (err) {
    if (hasCodeField(err) && err.code === 4001) {
			// ユーザーが接続を拒否した場合
      console.log('Please Install MetaMask.');
    } else {
      console.error(err);
    }
  }
  return '';
};

//ユーザー型定義　errorがunkown型なので、any型として扱う
const hasCodeField = (error: any): error is any => {
  return 'code' in error;
};

// ===============================================================
// 画面描画
// ===============================================================
// 文字列表示部分
const Content: FC<{contract: ethers.Contract}> = ({contract}) => {
  // useContentの返却値をそれぞれ定義
  const {
    account,
    isVoted,
    voteMessage, 
    voteCountOfAll, 
    voteCountOfFirstChoice, 
    voteCountOfSecondChoice, 
    requestVote, 
    updateVoteChoice, 
    updateVoteMessage 
  } = useContent(contract);
  // 投票ボタンの処理
  const executeVote = async () => {
    await requestVote();
    window.location.reload();
  }
  console.log("投票済みかどうか: ", Boolean(isVoted));
  return (
		<div>
			<p>{`あなたのmetamaskアドレス: ${account}`}</p><br></br>
      <p>{`総票数: ${voteCountOfAll}`}</p><br></br>
    	<table id="mainTable" align="center">
	  	  <tbody>
					<tr>
	  	      <td id="questionField" colSpan={2}>
							<p>あなたはどちらが好き?</p>
						</td>
	  	    </tr>
					<tr>
	  	      <td id="leftItemField">
							<p>犬</p>
						</td>
	  	      <td id="rightItemField">
							<p>猫</p>
						</td>
	  	    </tr>
					<tr>
	  	      <td id="leftVoteCountField">
							<p>{`得票数: ${voteCountOfFirstChoice}`}</p>
						</td>
	  	      <td id="rightVoteCountField">
							<p>{`得票数: ${voteCountOfSecondChoice}`}</p>
						</td>
	  	    </tr>
	  	    <tr>
	  	      <td id="leftField">
							<input type="radio" name="theme" onChange={updateVoteChoice} value="dog"/>
						</td>
	  	      <td id="rightField">
							<input type="radio" name="theme" onChange={updateVoteChoice} value="cat"/>
						</td>
	  	    </tr>
					<tr>
						<td id="middleField" colSpan={2}>
              {/* {isVoted || isVoted == 'undefined'
                ? <p>{`あなたはすでに投票済みです`}</p>
                : <p><input onChange={updateVoteMessage} placeholder="応援コメントはこちら" id="commentInput" /><br></br><br></br>
                  <button onClick={executeVote} id="voteButton">投票する</button></p>} */}
              <p><input onChange={updateVoteMessage} placeholder="応援コメントはこちら" id="commentInput" /><br></br><br></br>
              <button onClick={executeVote} id="voteButton">投票する</button></p>
						</td>
					</tr>
					<tr>
						<td id="commentField" colSpan={2}>
              <p>みんなのコメント</p><br></br>
              {voteMessage.map((p, index) => <tr key={`投票No.${index}`}>
                <td>{p.message}</td>
              </tr>)}
						</td>
					</tr>
    	  </tbody>
    	</table>
		</div>
  );
}

// 画面全体の表示部分
export const App: FC = () => {
  // ------------------------------
  // ローカルテストネット用
  // ------------------------------
  // const provider = new ethers.providers.JsonRpcProvider(); // フロントからアクセスする設定
  // const signer = provider.getSigner(); // ローカルネットワークの1件目のダミーアカウントを取得
  // const contract = new ethers.Contract(contractAddress, artifact.abi, signer); // コントラクトの抽象化（Signerでトランザクションに署名し、リクエストに含めることで更新処理を可能に）
  // const contractWithSigner = contract.connect(signer); // contractとアカウントの接続

  // ------------------------------
  // alchemy用
  // ------------------------------
  // provider作成
  const network = 'goerli'
  const provider = ethers.getDefaultProvider(
    network,
    {alchemy: Config.apiUrl}
  );
  // Signer作成
  const signer = new ethers.Wallet(
    Config.privateKey,
    provider
  )
  // Contract作成
  const contract = new ethers.Contract(contractAddress, artifact.abi, signer);
  const contractWithSigner = contract.connect(signer);

  return (
    <div style={{textAlign:"center"}}>
			<br></br>
      <h1>Vote DApp</h1>
			<br></br>
      <Content contract={contractWithSigner} />
    </div>
  )
}