type _Config = {
  apiUrl: string;
  privateKey: string;
}

const Config: _Config = {
  apiUrl: process.env.API_KEY || "",
  privateKey: process.env.PRIVATE_KEY || ""
}
  
export default Config